const actions = {
  FETCH_HEALTH: "FETCH_HEALTH",
  FETCH_HEALTH_SUCCESS: "FETCH_HEALTH_SUCCESS",
  FETCH_HEALTH_SEARCH: "FETCH_HEALTH_SEARCH",
  HEALTH_TOGGLE: "HEALTH_TOGGLE",
  HEALTH_TOGGLE_SUCCESS: "HEALTH_TOGGLE_SUCCESS",
  ADD_TICKET: "ADD_TICKET",
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  FETCH_CATEGORY: "FETCH_CATEGORY",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  FETCH_SCHOOLS: "FETCH_SCHOOLS_WEB",
  FETCH_SCHOOL_SUCCESS: "FETCH_SCHOOL_SUCCESS",
  TYPE_AREA: "TYPE_AREA",
  TYPE_AREA_SUCCESS: "TYPE_AREA_SUCCESS",
  fetchHealth: () => ({
    type: actions.FETCH_HEALTH,
  }),
  fetchHealthSearch: (payload) => ({
    type: actions.FETCH_HEALTH_SEARCH,
    payload,
  }),
  fetchTypeandArea: () => ({
    type: actions.TYPE_AREA,
  }),
  fetchCategoryTypes: () => ({
    type: actions.FETCH_CATEGORY,
  }),
  healthToggle: (payload, cb) => ({
    type: actions.HEALTH_TOGGLE,
    payload,
    cb,
  }),
  addNewTicket: (payload, cb) => ({
    type: actions.ADD_TICKET,
    payload,
    cb,
  }),
  sendCustomPush: (payload, cb) => ({
    type: actions.SEND_NOTIFICATION,
    payload,
    cb,
  }),
  updateCategory: (payload, cb) => ({
    type: actions.UPDATE_CATEGORY,
    payload,
    cb,
  }),
  fetchSchools: () => ({
    type: actions.FETCH_SCHOOLS,
  }),
};
export default actions;
