const actions = {
  FETCH_GRAPH_DATA: "FETCH_GRAPH_DATA",
  FETCH_GRAPH_DATA_SUCCESS: "FETCH_GRAPH_DATA_SUCCESS",
  FETCH_METABASE: "FETCH_METABASE",
  FETCH_METABASE_SUCCESS: "FETCH_METABASE_SUCCESS",
  FETCH_CONFIGS_DASHBOARD: "FETCH_CONFIGS_DASHBOARD",
  FETCH_CONFIGS_DASHBOARD_SUCCESS: "FETCH_CONFIGS_DASHBOARD_SUCCESS",
  UPLOAD_SCHOOL_ADMIN_AVATAR: "UPLOAD_SCHOOL_ADMIN_AVATAR",
  REMOVE_ADMIN_AVATAR: "REMOVE_ADMIN_AVATAR",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",

  fetchData: (payload, spinHandler) => ({
    type: actions.FETCH_GRAPH_DATA,
    spinHandler,
    payload,
  }),
  fetchConfigs: () => ({
    type: actions.FETCH_CONFIGS_DASHBOARD,
  }),
  fetchMetabase: (spinHandler, payload) => ({
    type: actions.FETCH_METABASE,
    spinHandler,
    payload,
  }),
  uploadAvatar: (payload, userId, cb) => ({
    type: actions.UPLOAD_SCHOOL_ADMIN_AVATAR,
    payload,
    userId,
    cb,
  }),
  removeAvatar: (payload, userId, cb) => ({
    type: actions.REMOVE_ADMIN_AVATAR,
    payload,
    userId,
    cb,
  }),
  changePassword: (payload, userId, loader) => ({
    type: actions.CHANGE_PASSWORD,
    payload,
    userId,
    loader,
  }),
};
export default actions;
