import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  tests: [],
  subjects: [],
  grades: [],
  schools: [],
  test: null,
  key: null,
  loading: false,
  table_loading: false,
  uploadStatus: false,
  error: "",
  testReport: [],
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_MCQ_TESTS_SUCCESS:
      return state.set("tests", action.tests).set("uploadStatus", false);

    case actions.GET_ALL_SUBJECTS_SUCCESS:
      return state.set("subjects", action.subjects);

    case actions.GET_ALL_CLASSES_SUCCESS:
      return state.set("grades", action.grades);

    case actions.GET_ALL_SCHOOLS_SUCCESS:
      return state.set("schools", action.schools);

    case actions.UPLOAD_TEST_DETAILS_SUCCESS:
      return state.set("uploadStatus", true);

    case actions.SET_MCQ_LOADING:
      return state.set("loading", action.loading);

    case actions.SET_TABLE_LOADING:
      return state.set("table_loading", action.loading);

    case actions.SET_MCQ_ERROR:
      return state.set("error", action.error);

    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state
        .set("tests", [])
        .set("subjects", [])
        .set("grades", [])
        .set("test", null);
    case actions.SET_TEST_REPORT:
      return state.set("testReport", action.payload.data);
    case actions.RESET_ALL_SCHOOLS:
      return state.set("schools", []);
    default:
      return state;
  }
}
