import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchSubjects() {
  yield takeEvery(actions.FETCH_SUBJECTS, function*(action) {
    try {
      const resp = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_SUBJECTS_SUCCESS,
        subjects: resp.data.subjects,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSubjectsAutoComplete() {
  yield takeEvery(actions.FETCH_SUBJECTS_AUTO_COMPLETE, function*(action) {
    try {
      const resp = yield call(API.subjects.listV2, action.payload);
      yield put({
        type: actions.FETCH_SUBJECTS_AUTO_COMPLETE_SUCCESS,
        subjects: Array.isArray(resp?.data?.data) ? resp.data.data : [],
      });
      if (action.cb) yield call(action.cb, true);
    } catch (err) {
      if (action.cb) yield call(action.cb, false);
      Notification(
        "error",
        err?.error?.message || "Session timed out. Please login again"
      );
    }
  });
}

export function* saveSubject() {
  yield takeEvery(actions.SAVE_SUBJECT, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
        data.append("filename", action.payload.file.originFileObj.name);
      }
      data.append("title", action.payload.title);
      if (action.ssbjectId) {
        yield call(API.subjects.update, data, action.ssbjectId);
        message = "Subject updated successfully";
      } else {
        yield call(API.subjects.add, data);
        message = "Subject created successfully";
      }
      const subjects = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_SUBJECTS_SUCCESS,
        subjects: subjects.data.subjects,
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteSubject() {
  yield takeEvery(actions.DELETE_SUBJECT, function*(action) {
    try {
      yield call(API.subjects.delete, action.subjectId);
      const resp = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_SUBJECTS_SUCCESS,
        subjects: resp.data.subjects,
      });
      Notification("success", "Subject deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSubjects),
    fork(saveSubject),
    fork(deleteSubject),
    fork(fetchSubjectsAutoComplete),
  ]);
}
