const actions = {
  FETCH_SUBJECTS: "FETCH_SUBJECTS",
  FETCH_SUBJECTS_AUTO_COMPLETE: "FETCH_SUBJECTS_AUTO_COMPLETE",
  FETCH_SUBJECTS_AUTO_COMPLETE_SUCCESS: "FETCH_SUBJECTS_AUTO_COMPLETE_SUCCESS",
  FETCH_SUBJECTS_SUCCESS: "FETCH_SUBJECTS_SUCCESS",
  SAVE_SUBJECT: "SAVE_SUBJECT",
  DELETE_SUBJECT: "DELETE_SUBJECT",
  SET_FILTER: "SET_FILTER",
  RESET: "RESET",
  RESET_SUBJECTS_AUTO_COMPLETE: "RESET_SUBJECTS_AUTO_COMPLETE",
  fetchSubjects: (spinHandler) => ({
    type: actions.FETCH_SUBJECTS,
    spinHandler,
  }),
  fetchSubjectAutoComplete: (payload, cb) => ({
    type: actions.FETCH_SUBJECTS_AUTO_COMPLETE,
    payload,
    cb,
  }),
  saveSubject: (payload, ssbjectId, cb) => ({
    type: actions.SAVE_SUBJECT,
    payload,
    ssbjectId,
    cb,
  }),
  deleteSubject: (payload, subjectId, spinHandler) => ({
    type: actions.DELETE_SUBJECT,
    payload,
    subjectId,
    spinHandler,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  resetSubjectAutoComplete: () => ({
    type: actions.RESET_SUBJECTS_AUTO_COMPLETE,
  }),
};
export default actions;
