import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  history: [],
  schools: [],
  grades: [],
  subjects: [],
  roles: [],
  userList: [],
  bbuserList: [],
  bbadminList: [],
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_HISTORY_MEETING_SUCCESS:
      return state.set("history", action.data);
    case actions.FETCH_CONFIGS_SUCCESS_BBM:
      return state
        .set("schools", action.schools)
        .set("grades", action.grades)
        .set("subjects", action.subjects)
        .set("roles", action.role);
    case actions.FETCH_USER_LIST_BBB_SUCCESS:
      return state.set("userList", action.users);
    case actions.FETCH_BBB_USERS_SUCCESS:
      return state.set("bbuserList", action.users);
    case actions.FETCH_BBB_ADMIN_SUCCESS:
      return state.set("bbadminList", action.admin);
    default:
      return state;
  }
}
