import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  data: [],
  schools: null,
  grades: null,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_GRAPH_DATA_SUCCESS:
      return state.set("data", action.data);
    case action.FETCH_METABASE_SUCCESS:
      return state.set("metabase"),action.data;
    case actions.FETCH_CONFIGS_DASHBOARD_SUCCESS:
      return state.set("schools", action.schools).set("grades", action.grades);
    default:
      return state;
  }
}
