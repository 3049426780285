import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* saveArea() {
  yield takeEvery(actions.SAVE_AREA, function* (action) {
    try {
      let resp, message;
      if (action.ticketAreaId) {
        resp = yield call(API.area.update, action.payload, action.ticketAreaId);
        message = "Ticket Area updated successfully";
      } else {
        resp = yield call(API.area.add, action.payload);
        message = "Ticket Area created successfully";
      }

      Notification("success", message);
      yield call(action.cb);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error ? err.error.message : "Request failed"
      );
    }
  });
}
export function* deleteArea() {
  yield takeEvery(actions.DELETE_AREA, function* (action) {
    try {
      yield call(API.area.delete, action.ticketAreaId);
      Notification("success", "Ticket Area deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(saveArea), fork(deleteArea)]);
}
