import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchUserAdmin() {
  yield takeEvery(actions.FETCH_USERS_ADMIN, function* (action) {
    try {
      const users = yield call(API.admin.list, action.payload);
      if (users) {
        yield put({
          type: actions.FETCH_USERS_ADMIN_SUCCESS,
          users: users.data.data,
          totalCount: users.data.count,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleUser() {
  yield takeEvery(actions.FETCH_USER_ADMIN_DETAILS, function* (action) {
    try {
      const resp = yield call(API.admin.details, action.userId);
      yield put({
        type: actions.FETCH_USER_ADMIN_DETAILS_SUCCESS,
        user: resp.data.data,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveUser() {
  yield takeEvery(actions.SAVE_ADMIN_USER, function* (action) {
    try {
      let resp, message;
      if (action.userId) {
        resp = yield call(API.admin.update, action.payload, action.userId);
        message = "User updated successfully";
      } else {
        resp = yield call(API.admin.add, action.payload);
        message = "User created successfully";
      }
      Notification("success", message);
      yield call(action.loader, true);
    } catch (err) {
      yield call(action.loader, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateAvatar() {
  yield takeEvery(actions.UPLOAD_ADMIN_AVATAR, function* (action) {
    try {
      let resp, message;
      if (action.userId) {
        const data = new FormData();
        if (action.payload.file.originFileObj) {
          data.append("avatar", action.payload.file.originFileObj);
          data.append("userid", action.userId);
          resp = yield call(API.users.uploadAvatar, data, action.userId);
          message = "Avatar updated successfully";
        }
      }
      Notification("success", message);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteUser() {
  yield takeEvery(actions.DELETE_ADMIN, function* (action) {
    try {
      yield call(API.admin.delete, action.userId);
      const resp = yield call(API.admin.list);
      yield put({
        type: actions.FETCH_USERS_ADMIN_SUCCESS,
        users: resp.data.data,
      });
      Notification("success", "User deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateUserStatus() {
  yield takeEvery(actions.CHANGE_ADMIN_STATUS, function* (action) {
    try {
      yield call(API.admin.update, action.payload, action.userId);
      yield put({
        type: actions.CHANGE_ADMIN_STATUS_SUCCESS,
        userId: action.userId,
        isActive: action.payload.isActive,
      });
      yield call(action.cb, false);
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* multiDelete() {
  yield takeEvery(actions.DELETE_MULTIPLE, function* (action) {
    try {
      yield call(API.users.multiDelete, action.payload.userIds);
      const resp = yield call(API.users.list);
      yield put({
        type: actions.FETCH_USERS_ADMIN_SUCCESS,
        users: resp.data.data,
      });
      Notification("success", "Users Removed successfully");
      yield call(action.payload.callBack, false);
    } catch (err) {
      yield call(action.payload.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchool() {
  yield takeEvery(actions.FETCH_SCHOOL, function* (action) {
    try {
      const schools = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOL_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
      });
      yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchRoles() {
  yield takeEvery(actions.FETCH_ROLE, function* (action) {
    try {
      const roles = yield call(API.common.roles);
      yield put({
        type: actions.FETCH_ROLE_SUCCESS,
        roles: roles && roles.data.roles ? roles.data.roles : [],
      });
      yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchTeachers() {
  yield takeEvery(actions.FETCH_TEACHER, function* (action) {
    try {
      const resp = yield call(API.common.teachers, action.id);
      yield put({
        type: actions.FETCH_TEACHER_SUCCESS,
        teachers: resp && resp.data.users ? resp.data.users : [],
      });
      yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchAllAdminUsers() {
  yield takeEvery(actions.FETCH_ALL_USERS_ADMIN, function* (action) {
    try {
      const users = yield call(API.admin.list, action.payload);
      if (users) {
        yield put({
          type: actions.FETCH_ALL_USERS_ADMIN_SUCCESS,
          allUsers: users.data.data,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchool),
    fork(fetchRoles),
    fork(fetchUserAdmin),
    fork(saveUser),
    fork(updateAvatar),
    fork(deleteUser),
    fork(fetchSingleUser),
    fork(updateUserStatus),
    fork(multiDelete),
    fork(fetchTeachers),
    fork(fetchAllAdminUsers),
  ]);
}
