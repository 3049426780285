import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import authActions from "../../redux/auth/actions";

export function* fetchData() {
  yield takeEvery(actions.FETCH_GRAPH_DATA, function*(action) {
    try {
      const resp = yield call(API.dashboard.list1, action.payload);
      if (resp) {
        yield put({
          type: actions.FETCH_GRAPH_DATA_SUCCESS,
          data: resp.data.metabase.siteURL,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
    }
  });
}

export function* fetchMetaData() {
  yield takeEvery(actions.FETCH_METABASE, function*(action) {
    try {
      const resp = yield call(API.dashboard.list, action.payload);
      if (resp) {
        yield put({
          type: actions.FETCH_METABASE_SUCCESS,
          data: resp.data.metabase.siteURL,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification("error", err && err.error && "Error");
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS_DASHBOARD, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      const grades = yield call(API.common.grades);
      if (schools && grades) {
        yield put({
          type: actions.FETCH_CONFIGS_DASHBOARD_SUCCESS,
          grades: grades && grades.data.grades ? grades.data.grades : [],
          schools: schools && schools.data.schools ? schools.data.schools : [],
        });
      }
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateAvatar() {
  yield takeEvery(actions.UPLOAD_SCHOOL_ADMIN_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        const data = new FormData();
        if (action.payload.file.originFileObj) {
          data.append("avatar", action.payload.file.originFileObj);
          data.append("userid", action.userId);
          resp = yield call(API.users.uploadAvatar, data, action.userId);
          message = "Avatar updated successfully";
        }
        yield put({
          type: authActions.UPDATE_PROFILE,
          avatar: `avatar/${action.userId}/${action.payload.file.name}`,
        });
      }
      if (action?.cb) yield call(action.cb, true);
      Notification("success", message);
    } catch (err) {
      if (action?.cb) yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteAvatar() {
  yield takeEvery(actions.REMOVE_ADMIN_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        resp = yield call(API.admin.update, action.payload, action.userId);
        message = "Avatar has been removed";
        yield put({
          type: authActions.UPDATE_PROFILE,
          avatar: "",
        });
      }
      if (action?.cb) yield call(action.cb, true);
      Notification("success", message);
    } catch (err) {
      if (action?.cb) yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* changePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        resp = yield call(
          API.profile.changePassword,
          action?.payload,
          action.userId
        );
        message = "Password changed successfully";
      }
      Notification("success", message);
      if (action?.loader) yield call(action.loader, true);
    } catch (err) {
      if (action?.loader) yield call(action.loader, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchData)]);
  yield all([fork(fetchConfigs)]);
  yield all([fork(fetchMetaData)]);
  yield all([fork(updateAvatar)]);
  yield all([fork(deleteAvatar)]);
  yield all([fork(changePassword)]);
}
