import { all, call, takeEvery, put, fork, select } from "redux-saga/effects";
import Moment from "moment";

import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { listAllGroups } from "./selecters";
import { qbAdminId } from "../../settings";

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      const grades = yield call(API.common.grades);
      const subjects = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: grades && grades.data.grades ? grades.data.grades : [],
        subjects:
          subjects && subjects.data.subjects ? subjects.data.subjects : [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

const formatGroup = (apiResponse) => {
  let formattedOutput = [];
  apiResponse.forEach((item) => {
    let usersExceptQbAdmin = item?.occupants_ids || [];
    const qbAdminIndex = usersExceptQbAdmin?.indexOf(qbAdminId);
    if (qbAdminIndex > -1) usersExceptQbAdmin?.splice(qbAdminIndex, 1);

    formattedOutput.push({
      _id: item._id,
      title: item.name,
      users: usersExceptQbAdmin,
      noOfUsers: usersExceptQbAdmin?.length || 0,
      createdAt: Moment(item.created_at).format("DD-MMM-YYYY hh:mm a"),
      school: (item && item.data && item.data.school) || undefined,
      // accessionNumbers: item.accessionNo,
      grade: (item && item.data && item.data.grade) || undefined,
      subject: (item && item.data && item.data.subjects) || undefined,
    });
  });
  return formattedOutput;
};

const formatUserList = (apiResponse) => {
  let formattedOutput = [];
  apiResponse.forEach((item) => {
    if (item.quickblox && item.quickblox.id) {
      formattedOutput.push({
        name: `${item.firstName} ${item.lastName || ""}`,
        _id: item.quickblox.id,
      });
    }
  });
  return formattedOutput;
};

const formatHistory = (apiResponse) => {
  let formattedOutput = [];
  apiResponse.forEach((item) => {
    formattedOutput.push({
      _id: item._id,
      groudID: item.chat_dialog_id,
      dateSent: Moment(item.date_sent * 1000).format("hh:mm a DD-MM-YYYY"),
      deliveredIDs: item.delivered_ids,
      message: item.message || "[image]",
      readIDs: item.read_ids,
      senderID: item.sender_id,
      sender: `${item?.firstName || ""} ${item?.lastName || ""}`,
    });
  });
  return formattedOutput;
};

export function* fetchGroups() {
  yield takeEvery(actions.LIST_GROUP, function*(action) {
    try {
      if (action.payload.offset === 0) {
        yield put({
          type: actions.LIST_GROUP_SUCCESS,
          payload: [],
          total: 0,
        });
      }

      const groups = yield select(listAllGroups());
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.list, action.payload);

      if (resp?.data?.users?.items?.length) {
        let payload = [];
        if (Array.isArray(groups) && action.payload.offset !== 0) {
          payload = [...groups, ...formatGroup(resp.data.users.items)];
        } else {
          payload = formatGroup(resp.data.users.items);
        }
        yield put({
          type: actions.LIST_GROUP_SUCCESS,
          payload,
          total: resp.data.totalEntries,
        });
      }
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchHistory() {
  yield takeEvery(actions.GET_CHAT_HISTORY, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.history, action.payload);

      if (
        resp.data &&
        resp.data.users &&
        resp.data.users.data &&
        resp.data.users.data.items &&
        resp.data.users.data.items.length
      ) {
        yield put({
          type: actions.GET_CHAT_HISTORY_SUCCESS,
          payload: formatHistory(resp.data.users.data.items),
        });
      } else {
        Notification("error", "No Details Found!");
      }
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_USERS_FOR_GROUP, function*(action) {
    try {
      const resp = yield call(API.users.list, action.payload);

      if (resp.data && resp.data.users && resp.data.users.length) {
        yield put({
          type: actions.FETCH_USERS_FOR_GROUP_SUCCESS,
          payload: formatUserList(resp.data.users),
        });
      }
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* createGroup() {
  yield takeEvery(actions.CREATE_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.add, action.payload);

      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      if (resp.data.success) {
        yield put({
          type: actions.CREATE_GROUP_SUCCESS,
          completed: true,
        });
        Notification("success", "New group has been created!");

        yield put({
          type: actions.LIST_GROUP,
          payload: { limit: 50, offset: 0 },
        });
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* editGroup() {
  yield takeEvery(actions.EDIT_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.update, action.payload);

      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      if (resp.success) {
        yield put({
          type: actions.EDIT_GROUP_SUCCESS,
          completed: true,
        });

        Notification("success", "Successfully edited group details");

        yield put({
          type: actions.LIST_GROUP,
          payload: { limit: 50, offset: 0 },
        });
      } else {
        Notification("error", resp.message);
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteGroup() {
  yield takeEvery(actions.DELETE_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.delete, action.payload);
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      if (resp.data.success) {
        yield put({
          type: actions.DELETE_GROUP_SUCCESS,
        });
        Notification("success", "Group has been deleted successfully");
      } else {
        Notification("error", resp.data.message);
      }
      if (action?.spinner) yield call(action.spinner, true);
      yield put({
        type: actions.LIST_GROUP,
        payload: { limit: 50, offset: 0 },
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      if (action?.spinner) yield call(action.spinner, true);

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* sendChatReply() {
  yield takeEvery(actions.SEND_CHAT_REPLY, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const resp = yield call(API.groups.sendChatReply, action.payload);

      const payload = { groupid: action.payload.groupId };
      const resp1 = yield call(API.groups.history, payload);
      var formatter = formatHistory(resp1.data.users.data.items);
      yield put({
        type: actions.GET_CHAT_HISTORY_SUCCESS,
        payload: formatter,
      });

      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchGroups)]);
  yield all([fork(fetchUsers)]);
  yield all([fork(createGroup)]);
  yield all([fork(editGroup)]);
  yield all([fork(deleteGroup)]);
  yield all([fork(fetchHistory)]);
  yield all([fork(fetchConfigs)]);
  yield all([fork(sendChatReply)]);
}
