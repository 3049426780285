import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  devices: null,
  schools: null,
  grades: null,
  roles: null,
  users: null,
  key: null,
  summary: null,
  exporturl: null,
});

export default function deviceReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_DEVICES_SUCCESS:
      return state.set("devices", action.devices);
    case actions.FETCH_DEVICE_CONFIGS_SUCCESS:
      return state
        .set("schools", action.schools)
        .set("grades", action.grades)
        .set("roles", action.roles);
    case actions.FETCH_DEVICE_USERS_SUCCESS:
      return state.set("users", action.users);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("devices", null);
    case actions.DEVICES_SUMMARY:
      return state.set("summary", action.summary);
    case actions.EXPORT_TRANSCATION_SUCCESS:
      return state.set("exporturl", action.exportUrl);
    default:
      return state;
  }
}
