import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchHealth() {
  yield takeEvery(actions.FETCH_HEALTH, function*(action) {
    try {
      const response = yield call(API.health.list, action.payload);
      yield put({
        type: actions.FETCH_HEALTH_SUCCESS,
        data: response.data.healthReport,
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchHealthSearch() {
  yield takeEvery(actions.FETCH_HEALTH_SEARCH, function*(action) {
    try {
      const response = yield call(API.health.listSearch, action.payload);
      yield put({
        type: actions.FETCH_HEALTH_SUCCESS,
        data: response.data.healthReport,
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      yield call(action.cb);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchTypeandArea() {
  yield takeEvery(actions.TYPE_AREA, function*(action) {
    try {
      const types = yield call(API.health.Types);
      const areas = yield call(API.health.Areas);
      yield put({
        type: actions.TYPE_AREA_SUCCESS,
        types: types.data.data,
        areas: areas.data.data,
      });
    } catch (err) {}
  });
}
export function* fetchCategoryTypes() {
  yield takeEvery(actions.FETCH_CATEGORY, function*(action) {
    try {
      const types = yield call(API.health.getTypes);
      const areas = yield call(API.health.getAreas);
      yield put({
        type: actions.FETCH_CATEGORY_SUCCESS,
        types: types.data.data,
        areas: areas.data.data,
      });
    } catch (err) {}
  });
}

export function* healthToggle() {
  yield takeEvery(actions.HEALTH_TOGGLE, function*(action) {
    try {
      const response = yield call(API.health.toggle, action.payload);

      // if (response.data.message === "Success") {
      //   yield put({
      //     type: actions.FETCH_HEALTH,
      //   });
      // }

      yield put({
        type: actions.HEALTH_TOGGLE_SUCCESS,
      });
      yield call(action.cb);
    } catch (err) {
      if (action.cb) yield call(action.cb);
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addTicket() {
  yield takeEvery(actions.ADD_TICKET, function*(action) {
    try {
      yield call(API.health.create, action.payload);
      Notification("success", "New support ticket created");
      // const response = yield call(API.health.list);
      // yield put({
      //   type: actions.FETCH_HEALTH_SUCCESS,
      //   data: response.data.healthReport,
      // });
      yield call(action.cb, true);
    } catch (err) {
      yield call(action.cb, false);
      // yield put({
      //   type: actions.SET_LOADING,
      //   loading: false,
      // });

      Notification(
        "error",
        err?.error?.message ||
          err?.message ||
          "Session timed out. Please login again"
      );
    }
  });
}

export function* sendPush() {
  yield takeEvery(actions.SEND_NOTIFICATION, function*(action) {
    try {
      yield call(API.health.notification, action.payload);
      Notification("success", "Notification sent successfully");
      yield call(action.cb);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* updateCategory() {
  yield takeEvery(actions.UPDATE_CATEGORY, function*(action) {
    try {
      yield call(API.health.updateCategory, action.payload);
      const response = yield call(API.health.list);
      yield put({
        type: actions.FETCH_HEALTH_SUCCESS,
        data: response.data.healthReport,
      });
      Notification("success", "Category has been updated successfully");
      yield call(action.cb);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchSchool() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOL_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
      });
      // yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchHealth)]);
  yield all([fork(fetchHealthSearch)]);
  yield all([fork(fetchCategoryTypes)]);
  yield all([fork(healthToggle)]);
  yield all([fork(addTicket)]);
  yield all([fork(sendPush)]);
  yield all([fork(updateCategory)]);
  yield all([fork(fetchSchool)]);
  yield all([fork(fetchTypeandArea)]);
}
