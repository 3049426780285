import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  schools: null,
  school: null,
  key: null,
  status: "",
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SCHOOLS_SUCCESS:
      return state.set("schools", action.schools);
    case actions.FETCH_SCHOOL_DETAILS_SUCCESS:
      return state.set("school", action.school);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("schools", null).set("school", null);
    case actions.CHANGE_STATUS_SCHOOL_SUCCESS:
      console.log(action);
      const users = Object.assign([], state.get("users"));
      const userInd = users.findIndex((user) => user._id == action.schoolId);
      if (userInd > -1) {
        users[userInd].verified = action.verified;
      }
      return state.set("users", users);
    default:
      return state;
  }
}
