import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchGrades() {
  yield takeEvery(actions.FETCH_GRADES, function*(action) {
    try {
      const resp = yield call(API.grades.list);
      const grd = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_GRADES_SUCCESS,
        grades: resp.data.grades,
      });
      yield put({
        type: actions.FETCH_GRADE_SUBJECT_SUCCESS,
        subjects: grd.data.subjects,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchGradesAutoComplete() {
  yield takeEvery(actions.FETCH_GRADES_AUTOCOMPLETE, function*(action) {
    try {
      const resp = yield call(API.grades.listV2, action.payload);
      yield put({
        type: actions.FETCH_GRADES_AUTOCOMPLETE_SUCCESS,
        grades: Array.isArray(resp?.data?.data) ? resp.data.data : [],
      });
      if (action.cb) yield call(action.cb, true);
    } catch (err) {
      if (action.cb) yield call(action.cb, false);
      Notification(
        "error",
        err?.error?.message || "Session timed out. Please login again"
      );
    }
  });
}

export function* saveGrade() {
  yield takeEvery(actions.SAVE_GRADE, function*(action) {
    try {
      let resp, message;
      if (action.gradeId) {
        resp = yield call(API.grades.update, action.payload, action.gradeId);
        message = "Grade updated successfully";
      } else {
        resp = yield call(API.grades.add, action.payload);
        message = "Grade created successfully";
      }
      Notification("success", message);
      yield call(action.cb);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error ? err.error.message : "Request failed"
      );
    }
  });
}

export function* deleteGrade() {
  yield takeEvery(actions.DELETE_GRADE, function*(action) {
    try {
      yield call(API.grades.update, action.payload, action.gradeId);
      Notification("success", "Grade deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchGrades),
    fork(saveGrade),
    fork(deleteGrade),
    fork(fetchGradesAutoComplete),
  ]);
}
