const actions = {
  FETCH_WEBSITES: "FETCH_WEBSITES",
  FETCH_WEBSITES_SUCCESS: "FETCH_WEBSITES_SUCCESS",
  SAVE_WEBSITE: "SAVE_WEBSITE",
  DELETE_WEBSITE: "DELETE_WEBSITE",
  FETCH_BONUS_WEBSITES: "FETCH_BONUS_WEBSITES",
  FETCH_BONUS_WEBSITES_SUCCESS: "FETCH_BONUS_WEBSITES_SUCCESS",
  SAVE_BONUS_WEBSITE: "SAVE_BONUS_WEBSITE",
  DELETE_BONUS_WEBSITE: "DELETE_BONUS_WEBSITE",
  FETCH_BONUS_CONFIGS: "FETCH_BONUS_CONFIGS",
  FETCH_BONUS_CONFIGS_SUCCESS: "FETCH_BONUS_CONFIGS_SUCCESS",
  FETCH_BONUS_USERS: "FETCH_BONUS_USERS",
  FETCH_BONUS_USERS_SUCCESS: "FETCH_BONUS_USERS_SUCCESS",
  SET_FILTER: "SET_FILTER",
  FETCH_SCHOOLS: "FETCH_SCHOOLS_WEB",
  FETCH_SCHOOL_SUCCESS: "FETCH_SCHOOL_SUCCESS",
  GET_USER_RECORD_WEB: "GET_USER_RECORD_WEB",
  GET_USER_RECORD_WEB_SUCCESS: "GET_USER_RECORD_WEB_SUCCESS",
  RESET: "RESET",
  fetchWebsites: (spinHandler) => ({
    type: actions.FETCH_WEBSITES,
    spinHandler,
  }),
  saveWebsite: (payload, websiteId, cb) => ({
    type: actions.SAVE_WEBSITE,
    payload,
    websiteId,
    cb,
  }),
  deleteWebsite: (payload, websiteId, spinHandler) => ({
    type: actions.DELETE_WEBSITE,
    payload,
    websiteId,
    spinHandler,
  }),
  fetchBonusWebsites: (spinHandler) => ({
    type: actions.FETCH_BONUS_WEBSITES,
    spinHandler,
  }),
  saveBonusWebsite: (payload, websiteId, cb) => ({
    type: actions.SAVE_BONUS_WEBSITE,
    payload,
    websiteId,
    cb,
  }),
  deleteBonusWebsite: (payload, websiteId, spinHandler) => ({
    type: actions.DELETE_BONUS_WEBSITE,
    payload,
    websiteId,
    spinHandler,
  }),
  fetchBonusConfigs: () => ({
    type: actions.FETCH_BONUS_CONFIGS,
  }),
  fetchBonusUsers: (payload, spinner) => ({
    type: actions.FETCH_BONUS_USERS,
    payload,
    spinner,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  fetchSchools: () => ({
    type: actions.FETCH_SCHOOLS,
  }),
  getUsersRecord: (id, callBack) => ({
    type: actions.GET_USER_RECORD_WEB,
    id,
    callBack,
  }),
};
export default actions;
