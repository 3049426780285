import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  books: null,
  book: {},
  bonusContents: null,
  bonusBook: null,
  roles: null,
  subjects: null,
  schools: null,
  grades: null,
  users: null,
  key: null,
  schools: null,
  tests: [],
  usersList: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_BOOKS_SUCCESS:
      return state.set("books", action.books);
    case actions.FETCH_BOOK_DETAILS_SUCCESS:
      return state.set("book", action.book);
    case actions.FETCH_BONUS_BOOKS_SUCCESS:
      return state.set("bonusContents", action.bonusContents);
    case actions.FETCH_BONUS_BOOK_DETAILS_SUCCESS:
      return state.set("bonusBook", action.bonusBook);
    case actions.FETCH_BOOK_CONFIGS_SUCCESS:
      return state
        .set("roles", action.roles)
        .set("subjects", action.subjects)
        .set("schools", action.schools)
        .set("grades", action.grades);
    case actions.FETCH_BONUS_CONTENT_USERS_SUCCESS:
      return state.set("users", action.users);
    case actions.FETCH_ACCESSION_USERS_SUCCESS:
      return state.set("usersList", action.usersList);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state
        .set("books", null)
        .set("book", null)
        .set("bonusContents", null)
        .set("bonusBook", null);
    case actions.FETCH_SCHOOLS_SUCCESS:
      return state.set("schools", action.schools).set("loading", false);
    case actions.FETCH_LESSON_TESTS_SUCCESS:
      return state.set("tests", action.tests);
    default:
      return state;
  }
}
