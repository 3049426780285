const actions = {
  GET_HISTORY_MEETING: "GET_HISTORY_MEETING",
  GET_HISTORY_MEETING_SUCCESS: "GET_HISTORY_MEETING_SUCCESS",
  FETCH_CONFIGS_BBM: "FETCH_CONFIGS_BBM",
  FETCH_CONFIGS_SUCCESS_BBM: "FETCH_CONFIGS_SUCCESS_BBM",
  SAVE_BBM: "SAVE_BBM",
  DELETE_BBM: "DELETE_BBM",
  FETCH_USER_LIST_BBB: "FETCH_USER_LIST_BBB",
  FETCH_USER_LIST_BBB_SUCCESS: "FETCH_USER_LIST_BBB_SUCCESS",
  END_CALL: "END_CALL",
  FETCH_BBB_USERS: "FETCH_BBB_USERS",
  FETCH_BBB_USERS_SUCCESS: "FETCH_BBB_USERS_SUCCESS",
  FETCH_BBB_ADMIN: "FETCH_BBB_ADMIN",
  FETCH_BBB_ADMIN_SUCCESS: "FETCH_BBB_ADMIN_SUCCESS",
  fetchHistory: (callBack, payload) => ({
    type: actions.GET_HISTORY_MEETING,
    callBack,
    payload,
  }),
  fetchHistoryMeetingSuccess: (data) => ({
    type: actions.GET_HISTORY_MEETING_SUCCESS,
    data,
  }),
  fetchConfigs: () => ({
    type: actions.FETCH_CONFIGS_BBM,
  }),
  fetchBBUserList: (payload) => ({
    type: actions.FETCH_BBB_USERS,
    payload,
  }),
  fetchBBAdminList: (payload) => ({
    type: actions.FETCH_BBB_ADMIN,
    payload,
  }),
  saveBbm: (payload, callBack, edit) => ({
    type: actions.SAVE_BBM,
    payload,
    callBack,
    edit,
  }),
  deleteHistory: (id, callBack) => ({
    type: actions.DELETE_BBM,
    id,
    callBack,
  }),
  fetchUserList: (payload, callBack) => ({
    type: actions.FETCH_USER_LIST_BBB,
    payload,
    callBack,
  }),
  endCallApi: (id, callBack) => ({
    type: actions.END_CALL,
    id,
    callBack,
  }),
};
export default actions;
