import { message } from "antd";
import { Map } from "immutable";
import { findIndex } from "lodash";
import orderBy from "lodash/orderBy";
import range from "lodash/range";
import moment from "moment";
import config from "../settings";

export const pageSize = 10;

export function clearToken() {
  localStorage.clear();
}

export function capitalizeFirstLetter(string = "") {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const beforePDFUpload = (file) => {
  const isPdf = file.type === "application/pdf";
  if (!isPdf) {
    message.error("You can only upload PDF file!");
  }
  const isLt100M = file.size / 1024 / 1024 < 100;
  if (!isLt100M) {
    message.error("PDF must smaller than 100MB!");
  }
  return isPdf && isLt100M && fileNameValidation(file);
};
export const beforeVideoUpload = (file) => {
  const isVideo = ["video/mp4", "video/x-m4v", "video/*"].includes(file.type);
  if (!isVideo) {
    message.error("You can only upload Video file!");
  }
  const isLt100M = file.size / 1024 / 1024 < 100;
  if (!isLt100M) {
    message.error("Video must smaller than100MB!");
  }
  return isVideo && isLt100M && fileNameValidation(file);
};

export const beforeVideoMp3Upload = (file) => {
  const isVideo = ["video/mp4", "video/x-m4v", "video/*,","audio/mpeg","audio/*"].includes(file.type);
  if (!isVideo) {
    message.error("You can only upload Video or Mp3 file!");
  }
  const isLt100M = file.size / 1024 / 1024 < 100;
  if (!isLt100M) {
    message.error("Video must smaller than100MB!");
  }
  return isVideo && isLt100M && fileNameValidation(file);
};
export const acceptImageTypes = ".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"; //,.gif,.GIF,.svg,.SVG"; //,.webp,.WEBP";
export const acceptXlTypes = ".csv"; //, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

const fileNameValidation = (file) => {
  const format = /[!%&?]/;
  const isValid = !format.test(file?.name || "");
  if (!isValid) {
    message.error("The file name is not valid!");
  }
  return isValid;
};
export const beforeImageUpload = (file) => {
  const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
  if (!isJpgOrPng) {
    message.error("You can only upload Image file!");
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt5M && fileNameValidation(file);
};

export function getSortParam(sorter) {
  try {
    if (sorter.order && sorter.columnKey) {
      const param = {
        sortOrder: sorter.order === "descend" ? "DESC" : "ASC",
        sortBy: sorter.columnKey || "",
      };
      return param;
    }
    return {
      sortOrder: "",
      sortBy: "",
    };
  } catch (err) {
    return {
      sortOrder: "",
      sortBy: "",
    };
  }
}
export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getProfile() {
  try {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return new Map({ profile });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function leaveDateFormat(str) {
  return moment(str).format("DD-MM-YYYY");
}

export function getMonthStartEnd(date) {
  const startOfMonth = moment(date)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment(date)
    .endOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");
  return {
    start: startOfMonth,
    end: endOfMonth,
  };
}

export function getDateStartEnd(date) {
  const start = moment(date).format("YYYY-MM-DD");
  const end = moment(date)
    .add(1, "days")
    .format("YYYY-MM-DD");
  return {
    start,
    end,
  };
}

export const readMore = (str, size = 40, dots = true) => {
  const hasReadMore = str?.length > size + 3;
  if (hasReadMore) return `${str.substring(0, size)}${dots ? "..." : ""}`;
  return str;
};

export function isSuperAdmin(role) {
  if (role !== "SUPER_ADMIN") {
    return false;
  } else {
    return true;
  }
}
export function formatPostDate(postDate) {
  return moment(postDate).format("DD-MM-YYYY hh:mm a");
}

export const filterOption = (input, option) =>
  option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export function formatSaleDate(date) {
  return moment(date).format("YYYY-MM-DD");
}

export const filterSort = (list = [], key, order = "asc") =>
  key ? orderBy(list, [(item) => item?.[key]?.toLowerCase()], [order]) : list;

export function serverBaseUrl() {
  return `${config.baseUrl}`;
}

export function genPaswd(firstName) {
  return `${firstName.substring(0, 4)}123`.toLowerCase();
}

export function emailToLink(string) {
  const regEx = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
  const converted = string.replace(regEx, '<a href="mailto:$1">$1</a>');
  // cons
  return converted;
}

export function formatEvents(events) {
  events.forEach((event, index) => {
    events[index].start = new moment(event.start).toDate();
    events[index].end = new moment(event.end).toDate();
  });
  return events;
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function allLanguages() {
  const lang = [
    {
      id: "english",
      value: "English",
    },
    {
      id: "sotho",
      value: "Sotho",
    },
    {
      id: "afrikaans",
      value: "Afrikaans",
    },
    {
      id: "xhosa",
      value: "Xhosa",
    },
    {
      id: "tswana",
      value: "Tswana",
    },
    {
      id: "zulu",
      value: "Zulu",
    },
  ];
  return lang;
}

export const validatNumber = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) {
      if (value.length > 15) {
        callback("Number length is too high");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};
export function formatAsZARCurrency(number) {
  if (typeof number !== 'number') {
    return 'Invalid input';
  }

  // Convert the number to a string
  var numberString = number.toFixed(0);

  // Split the number string into parts at the decimal point
  var parts = numberString.split('.');

  // Extract the integer part
  var integerPart = parts[0];

  // Add commas as thousands separators
  var formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer part and the decimal part (if it exists)
  var formattedNumber = parts.length > 1 ? formattedIntegerPart + '.' + parts[1] : formattedIntegerPart;

  return formattedNumber;
}


export const validatePercentage = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) {
      if (value < 0 || value > 100) {
        callback("Only allowed 0 to 100");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};

export const validatPhone = (rule, value, callback) => {
  if (value) {
    var reg = /^\+?[0-9]*$/;
    if (reg.test(value) == true) {
      if (value.length > 11) {
        callback("Number length is too high");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};
export const validatSIM = (rule, value, callback) => {
  if (value) {
    var reg = /^\+?[0-9]*$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};
export const validateCharacter = (rule, value, callback) => {
  if (value) {
    var reg = /^[a-zA-Z\s]*$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("Please enter a valid name");
    }
  } else {
    callback();
  }
};

export const validateEmail = (rule, value, callback) => {
  if (value) {
    var reg = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("Invalid email id");
    }
  } else {
    callback();
  }
};
export const validateRequired = (rule, value, callback, message) => {
  if (value) {
    if (value?.trim()) callback();
    else callback("Please fill in the field");
  } else {
    callback();
  }
};
export const validateName = (rule, value, callback) => {
  if (value) {
    var reg = /^\S*$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("White space not allowed");
    }
  } else {
    callback();
  }
};
export const validateString = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]+$/;
    if (reg.test(value) == true) {
      callback("Numbers not allowed");
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const validateURL = (rule, value, callback) => {
  if (value) {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (regex.test(value)) callback();
    else {
      callback("Invalid URL");
    }
  } else {
    callback();
  }
};

export const validateDateRange = (startDate, endDate, callback) => {
  if (startDate && endDate) {
    if (endDate.diff(startDate, "days") >= 1) callback();
    else {
      callback("Start date must be before end date");
    }
  }
};

export const validateProposal = (rule, value, callback) => {
  if (value) {
    const proposalNumbers = proposals.map((string) => string.trim());
    return new Set(proposalNumbers.split(",")).size === proposalNumbers.length;
  } else {
    callback();
  }
};
export const validateArray = (values, callback) => {
  if (values) {
    let flag = values?.every((value) => {
      if (!value || !value?.trim()) return false;
      return true;
    });
    if (flag) callback();
    else callback("Please fill options");
  } else {
    callback();
  }
};
export const validateArrayComa = (values, callback) => {
  if (values) {
    let flag = values?.every((value) => {
      var reg = /^[^,]*,[^,]*$/;
      return !reg.test(value);
    });
    if (flag) callback();
    else callback("Coma doesn't support in options");
  } else {
    callback();
  }
};

export const allWeeks = () => {
  const weeks = [];
  let week = 1;
  while (week < 53) {
    weeks.push({ id: week, title: `Week ${week}` });
    week++;
  }
  return weeks;
};

export const formatObject = (object = {}) => {
  let copyObj = {};
  for (const property in object)
    if (object[property] !== undefined) copyObj[property] = object[property];
  return copyObj;
};

export const disablePastTime = (curr) => {
  const diff = moment(moment().format("L")).diff(curr.format("L"), "days");
  if (diff === 0) {
    return {
      disabledHours: () => range(0, moment().format("H")),
      disabledMinutes: () =>
        moment().format("H") == curr.format("H")
          ? range(0, moment().format("m") - -1)
          : [],
    };
  } else if (diff < 0) {
  } else {
  }
};

const timeRange = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledTime = (date) => {
  if (date) {
    const now = moment().format("HH:mm");
    const hour = now.split(":")[0];
    const min = now.split(":")[1];
    if (date.diff(moment(), "days") === 0) {
      return {
        disabledHours: () => timeRange(0, hour),
        disabledMinutes: () => timeRange(0, min),
      };
    }
  }
};

export const showTime2Mins = {
  format: "HH:mm",
  defaultValue: moment().add(2, "minutes"),
};

export const combineArray = (collection1 = [], collection2 = []) => {
  const list = [...collection1];
  collection2?.forEach((item) => {
    const index = findIndex(collection1, ["_id", item?._id]);
    if (index === -1) list.push(item);
  });
  return list;
};

export const size = [
  { label: "Generic (R 50 million p/a)", value: "Generic (R 50 million p/a)" },
  { label: "QSE (R 10 -50 million p/a)", value: "QSE (R 10 -50 million p/a)" },
  { label: "EME (R 0 -10 million p/a)", value: "EME (R 0 -10 million p/a)" },
];
export const sectors = [
  { value: "General", label: "General" },
  { value: "Automotive", label: "Automotive" },
  {
    value: "Construction & Civil Engineering",
    label: "Construction & Civil Engineering",
  },
  { value: "Agriculture", label: "Agriculture" },
  { value: "ICT", label: "ICT" },
  { value: "Renewable", label: "Renewable" },
  { value: "Financial Sector", label: "Financial Sector" },
  { value: "Forestry", label: "Forestry" },
  { value: "Property", label: "Property" },
  { value: "Tourism", label: "Tourism" },
  {
    value: "Marketing Advertising and Communications",
    label: "Marketing Advertising and Communications",
  },
];

export const months = [
  { label: "Not Known", value: -1 },
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];
