const actions = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  SAVE_USER: "SAVE_USER",
  DELETE_USER: "DELETE_USER",
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  FETCH_CONFIGS: "FETCH_CONFIGS",
  FETCH_CONFIGS_SUCCESS: "FETCH_CONFIGS_SUCCESS",
  UPLOAD_AVATAR: "UPLOAD_AVATAR",
  SET_FILTER: "SET_FILTER",
  CHANGE_STATUS: "CHANGE_STATUS",
  CHANGE_STATUS_SUCCESS: "CHANGE_STATUS_SUCCESS",
  RESET: "RESET",
  DELETE_MULTIPLE: "DELETE_MULTIPLE",
  BULK_UPDATE_USERS: "BULK_UPDATE_USERS",
  BULK_UPDATE_USERS_SUCCESS: "BULK_UPDATE_USERS_SUCCESS",
  SET_LOADING: "SET_LOADING",

  fetchUsers: (payload, spinHandler) => ({
    type: actions.FETCH_USERS,
    payload,
    spinHandler,
  }),
  bulkUpdateUsers: (payload, cb) => ({
    type: actions.BULK_UPDATE_USERS,
    payload,
    cb,
  }),
  saveUser: (payload, userId, loader) => ({
    type: actions.SAVE_USER,
    payload,
    userId,
    loader,
  }),
  deleteUser: (payload, userId, spinHandler) => ({
    type: actions.DELETE_USER,
    payload,
    userId,
    spinHandler,
  }),
  fetchSingleuser: (userId, spinHandler) => ({
    type: actions.FETCH_USER_DETAILS,
    userId,
    spinHandler,
  }),
  fetchConfigs: () => ({
    type: actions.FETCH_CONFIGS,
  }),
  uploadAvatar: (payload, userId) => ({
    type: actions.UPLOAD_AVATAR,
    payload,
    userId,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  changeStatus: (payload, userId, cb) => ({
    type: actions.CHANGE_STATUS,
    payload,
    userId,
    cb,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  deleteMultiple: (userIds, callBack) => ({
    type: actions.DELETE_MULTIPLE,
    payload: { userIds, callBack },
  }),
};
export default actions;
