const actions = {
  CREATE_GROUP: "CREATE_GROUP",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",

  EDIT_GROUP: "EDIT_GROUP",
  EDIT_GROUP_SUCCESS: "EDIT_GROUP_SUCCESS",

  LIST_GROUP: "LIST_GROUP",
  LIST_GROUP_SUCCESS: "LIST_GROUP_SUCCESS",

  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",

  FETCH_USERS_FOR_GROUP: "FETCH_USERS_FOR_GROUP",
  FETCH_USERS_FOR_GROUP_SUCCESS: "FETCH_USERS_FOR_GROUP_SUCCESS",

  GET_CHAT_HISTORY: "GET_CHAT_HISTORY",
  GET_CHAT_HISTORY_SUCCESS: "GET_CHAT_HISTORY_SUCCESS",

  FETCH_CONFIGS: "FETCH__CHAT_GROUP_CONFIGS",
  FETCH_CONFIGS_SUCCESS: "FETCH__CHAT_GROUP_CONFIGS_SUCCESS",

  SET_LOADING: "SET_LOADING",
  SEND_CHAT_REPLY: "SEND_CHAT_REPLY",
  SEND_CHAT_REPLY_SUCCESS: "SEND_CHAT_REPLY_SUCCESS",

  getAllGroups: (payload) => ({
    type: actions.LIST_GROUP,
    payload,
  }),

  getChatHistory: (payload) => ({
    type: actions.GET_CHAT_HISTORY,
    payload,
  }),

  fetchUsers: (payload) => ({
    type: actions.FETCH_USERS_FOR_GROUP,
    payload,
  }),

  fetchConfigs: () => ({
    type: actions.FETCH_CONFIGS,
  }),

  editGroups: (payload) => ({
    type: actions.EDIT_GROUP,
    payload,
  }),

  createGroups: (payload) => ({
    type: actions.CREATE_GROUP,
    payload,
  }),

  deleteGroup: (payload, spinner) => ({
    type: actions.DELETE_GROUP,
    payload,
    spinner,
  }),
  sendChatReply: (payload) => ({
    type: actions.SEND_CHAT_REPLY,
    payload,
  }),
};
export default actions;
