const actions = {
  SAVE_INVESTOR_PROPOSAL: "SAVE_INVESTOR_PROPOSAL",
  SAVE_IMPACT_PROPOSAL: "SAVE_IMPACT_PROPOSAL",
  DELETE_INVESTOR_PROPOSAL: "DELETE_INVESTOR_PROPOSAL",
  FETCH_PROPOSALS: "FETCH_PROPOSALS",
  FETCH_PROPOSALS_SUCCESS: "FETCH_PROPOSALS_SUCCESS",
  FETCH_ALL_INVESTOR_PROPOSALS: "FETCH_ALL_INVESTOR_PROPOSALS",
  FETCH_ALL_INVESTOR_PROPOSALS_SUCCESS: "FETCH_ALL_INVESTOR_PROPOSALS_SUCCESS",
  FETCH_ALL_PROPOSAL_NUMBERS: "FETCH_ALL_PROPOSAL_NUMBERS",
  FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS: "FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS",
  RESET: "RESET",

  saveInvestor: (payload, investorId, cb) => ({
    type: actions.SAVE_INVESTOR_PROPOSAL,
    payload,
    investorId,
    cb,
  }),
  saveImpactProposal: (payload, investorId, cb) => ({
    type: actions.SAVE_IMPACT_PROPOSAL,
    payload,
    investorId,
    cb,
  }),

  deleteInvestorProposal: (investorId, spinHandler) => ({
    type: actions.DELETE_INVESTOR_PROPOSAL,
    investorId,
    spinHandler,
  }),
  fetchProposals: (payload = {}, spinHandler = null) => ({
    type: actions.FETCH_PROPOSALS,
    payload,
    spinHandler,
  }),
  uploadAvatar: (payload, investorId) => ({
    type: actions.UPLOAD_AVATAR,
    payload,
    investorId,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  fetchAllInvestors: (spinHandler = null) => ({
    type: actions.FETCH_ALL_INVESTOR_PROPOSALS,
    spinHandler,
  }),
  fetchAllProposalNumbers: (spinHandler = null) => ({
    type: actions.FETCH_ALL_PROPOSAL_NUMBERS,
    spinHandler,
  }),
};
export default actions;
