import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import userpic from "../../image/avatar.png";
import authAction from "../../redux/auth/actions";
import { getProfile } from "../../redux/auth/selectors";
import { serverBaseUrl } from "../../helpers/utility";
const { logout } = authAction;

class TopbarUserDetails extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      avatar: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      avatar: props?.user?.avatar?.admin
        ? `${serverBaseUrl()}/${props.user.avatar?.admin}`
        : userpic,
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  onErrorAvatar = () => {
    this.setState({ avatar: userpic });
  };

  render() {
    let { user } = this.props;
    const { avatar } = this.state;

    return (
      <div className="isoImgWrapper">
        <img alt="user" src={avatar} onError={this.onErrorAvatar} />
        <div style={{ padding: "0px 25px 0px 20px" }}>
          <Link
            onClick={() => {
              this.handleVisibleChange();
            }}
            to={`/dashboard/profile/${user._id}`}
            className="user-profile-link"
          >
            <p style={{ marginBottom: "3px" }}>
              <strong>
                {`Welcome`} {user.firstName}
              </strong>
            </p>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: getProfile(),
});

export default connect(
  mapStateToProps,
  { logout }
)(TopbarUserDetails);
