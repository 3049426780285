import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
const formatAccessionResponse = (res) => {
  return res.map((item) => ({
    _id: item._id,
    accessionnumber: item.accessionNo,
    name: `${item.firstName} ${item.lastName || ""}`,
    school: item.school,
    role: item.role,
    grade: item.grade,
    subjects: item.subjects,
  }));
};
export function* fetchBooks() {
  yield takeEvery(actions.FETCH_BOOKS, function*(action) {
    try {
      // const resp = yield call(API.books.list);
      const resp = yield call(API.books.search, action.payload);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleBook() {
  yield takeEvery(actions.GET_USER_RECORD, function*(action) {
    try {
      const resp = yield call(API.books.details, action.id);
      yield put({
        type: actions.FETCH_BOOK_DETAILS_SUCCESS,
        book: resp.data,
      });
      if (action.callBack) yield call(action.callBack, false);
    } catch (err) {
      if (action.callBack) yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBook() {
  yield takeEvery(actions.SAVE_BOOK, function*(action) {
    try {
      let res, message;
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      data.append("role", action.payload.role);
      data.append("forLesson", action.payload.forLesson || false);
      data.append("subject", action.payload.subject);
      data.append("language", action.payload.language);
      data.append("grade", action.payload.grade);
      data.append("bookId", action.payload.bookId);
      data.append("versions", JSON.stringify(action.payload.versions));

      if (action.bookId) {
        res = yield call(API.books.update, data, action.bookId);
        message = "Content updated successfully";
      } else {
        res = yield call(API.books.add, data);
        message = "Content added successfully";
      }
      Notification("success", message);
      if (action.payload.bookId) {
        yield put(
          push(`/dashboard/books_videos/details/${action.payload.bookId}`)
        );
      } else {
        yield put(push(`/dashboard/books_videos`));
      }
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBook() {
  yield takeEvery(actions.DELETE_BOOK, function*(action) {
    try {
      yield call(API.books.delete, action.bookId);
      const resp = yield call(API.books.list);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });
      Notification("success", "Book deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchBookConfigs() {
  yield takeEvery(actions.FETCH_BOOK_CONFIGS, function*(action) {
    try {
      const datas = {};
      try {
        const roles = yield call(API.common.roles);
        datas.roles = roles?.data?.roles || [];
      } catch (err) {}
      try {
        const subjects = yield call(API.common.subjectsV2);
        datas.subjects = subjects?.data?.data || [];
      } catch (err) {}
      try {
        const schools = yield call(API.common.schools);
        datas.schools = schools?.data?.schools || [];
      } catch (err) {}
      try {
        const grades = yield call(API.common.gradesV2);
        datas.grades = grades?.data?.data || [];
      } catch (err) {}
      yield put({
        type: actions.FETCH_BOOK_CONFIGS_SUCCESS,
        ...datas,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addBookVersion() {
  yield takeEvery(actions.ADD_BOOK_VERSION, function*(action) {
    try {
      let data = new FormData();
      if (action?.payload?.cover?.originFileObj) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action?.payload?.file?.originFileObj) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      if (action?.payload?.school?.length > 0) {
        data.append("school", JSON.stringify(action.payload.school));
      }
      if (action?.payload?.grade?.length > 0) {
        data.append("grades", JSON.stringify(action.payload.grade));
      }
      if (action?.payload?.tags?.length > 0) {
        data.append("hashtag", JSON.stringify(action.payload.tags));
      }
      if (action?.payload?.type) data.append("type", action.payload.type);
      if (action?.payload?.title) data.append("title", action.payload.title);
      if (action?.payload?.publication)
        data.append("publication", action.payload.publication);
      if (action?.payload?.description)
        data.append("description", action.payload.description);

      if (action?.payload?.week) data.append("week", action.payload.week);
      if (action?.payload?.test) data.append("test", action.payload.test);
      data.append("role", action.payload.role);
      if (action?.payload?.language)
        data.append("language", action.payload.language);
      data.append("subject", action.payload.subject);
      data.append(
        "qualityEngagementFactor",
        action.payload.qualityEngagementFactor
      );
      if (action?.payload?.fileId) data.append("fileId", action.payload.fileId);
      if (action?.payload?.bookId) data.append("bookId", action.payload.bookId);
      data.append("files", JSON.stringify(action.payload.files));
      data.append("forLesson", action?.payload?.forLesson || false);
      // return false;
      let message;
      if (action?.payload?.fileId) {
        yield call(API.books.updateV2, data, action.payload.fileId);
        message = "Library updated successfully";
      } else {
        yield call(API.books.add, data);
        message = "Library added successfully";
      }

      const resp = yield call(API.books.search);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });
      yield call(action.spinHandler);
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBookVersion() {
  yield takeEvery(actions.DELETE_BOOK_VERSION, function*(action) {
    try {
      yield call(API.books.version.delete, action.versionId);
      const resp = yield call(API.books.list);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });

      // const details = yield call(API.books.details, action.bookId);
      // yield put({
      //   type: actions.FETCH_BOOK_DETAILS_SUCCESS,
      //   book: details.data,
      // });
      Notification("success", "Content deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchBonusBooks() {
  yield takeEvery(actions.FETCH_BONUS_BOOKS, function*(action) {
    try {
      // const resp = yield call(API.bonusContent.list);
      const resp = yield call(API.bonusContent.search, action.payload);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleBonusBook() {
  yield takeEvery(actions.GET_USER_RECORD_BONUS, function*(action) {
    try {
      const resp = yield call(API.bonusContent.details, action.id);
      yield put({
        type: actions.FETCH_BONUS_BOOK_DETAILS_SUCCESS,
        bonusBook: resp.data,
      });
      yield call(action.callBack);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBonusBook() {
  yield takeEvery(actions.SAVE_BONUS_BOOK, function*(action) {
    try {
      let res, message;
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      if (action.payload.publication)
        data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      if (action.payload.role) data.append("role", action.payload.role);
      if (action.payload.subject)
        data.append("subject", action.payload.subject);
      if (action.payload.language)
        data.append("language", action.payload.language);
      if (action.payload.grade) data.append("grade", action.payload.grade);
      if (action.payload.bookId) data.append("bookId", action.payload.bookId);
      data.append("school", JSON.stringify(action.payload.school));
      data.append("users", JSON.stringify(action.payload.users));
      if (action.payload.techSupportId)
        data.append("techSupportId", action.payload.techSupportId);
      if (action.payload.creationMethod)
        data.append("creationMethod", action.payload.creationMethod);
      if (action.bookId) {
        yield call(API.bonusContent.update, data, action.bookId);
        message = " My Library updated successfully";
      } else {
        yield call(API.bonusContent.add, data);
        message = "My Library added successfully";
      }
      if (action.payload.techSupportId) {
        yield call(action.cb, true);
      }
      Notification("success", message);
      // yield put(push("/dashboard/bonus_content"));
    } catch (err) {
      if (action.cb) yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_BONUS_CONTENT_USERS, function*(action) {
    try {
      if (Object.keys(action.payload).length !== 0) {
        if (typeof action.payload.school === "string") {
          action.payload.school = [action.payload.school];
        }
        if (typeof action.payload.grade === "string") {
          action.payload.grade = [action.payload.grade];
        }
      }
      const users = yield call(API.users.autocompleteUserV2, action.payload);
      yield put({
        type: actions.FETCH_BONUS_CONTENT_USERS_SUCCESS,
        users: users?.data?.requests || [],
      });
      yield put({
        type: actions.FETCH_ACCESSION_USERS_SUCCESS,
        usersList: users?.data.requests
          ? formatAccessionResponse(users?.data.requests)
          : [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBonusBook() {
  yield takeEvery(actions.DELETE_BONUS_BOOK, function*(action) {
    try {
      yield call(API.bonusContent.delete, action.bookId);
      const resp = yield call(API.bonusContent.list);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.bonusContents,
      });
      Notification("success", "My Library deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error ? err.error.message : "Request failed"
      );
    }
  });
}

export function* addBonusContentVersion() {
  yield takeEvery(actions.ADD_BONUS_BOOK_VERSION, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }

      if (action.payload.school && action.payload.school.length > 0) {
        data.append("schools", JSON.stringify(action.payload.school));
      } else {
        data.append("schools", "");
      }
      data.append(
        "qualityEngagementFactor",
        action.payload.qualityEngagementFactor
      );
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      data.append("hashtag", JSON.stringify(action.payload.hashtag));
      data.append("role", action.payload.role);
      data.append("subject", action.payload.subject);
      data.append("grade", action.payload.grade);
      if (action.payload?.language)
        data.append("language", action.payload.language);
      if (action.payload?.fileId) data.append("fileId", action.payload.fileId);
      if (action.payload?.bookId) data.append("bookId", action.payload.bookId);
      data.append("files", JSON.stringify(action.payload.files));
      data.append("users", JSON.stringify(action.payload.users));
      let message;
      if (action.payload.fileId) {
        yield call(API.bonusContent.update, data, action.payload.fileId);
        message = "My Library updated successfully";
      } else {
        yield call(API.bonusContent.add, data);
        message = "My Library added successfully";
      }

      const resp = yield call(API.bonusContent.search);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      yield call(action.spinHandler);
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBonusBookVersion() {
  yield takeEvery(actions.DELETE_BONUS_BOOK_VERSION, function*(action) {
    try {
      yield call(API.bonusContent.version.delete, action.versionId);
      const resp = yield call(API.bonusContent.search, action.payload);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      Notification("success", "My Library deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchools() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const resp = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchLessonTests() {
  yield takeEvery(actions.FETCH_LESSON_TESTS, function*(action) {
    try {
      const resp = yield call(API.test.byFilter);
      yield put({
        type: actions.FETCH_LESSON_TESTS_SUCCESS,
        tests: resp?.data?.requests || [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchools),
    fork(fetchBooks),
    fork(saveBook),
    fork(addBookVersion),
    fork(deleteBook),
    fork(deleteBookVersion),
    fork(fetchSingleBook),
    fork(fetchBookConfigs),
    fork(fetchBonusBooks),
    fork(saveBonusBook),
    fork(fetchSingleBonusBook),
    fork(deleteBonusBook),
    fork(addBonusContentVersion),
    fork(deleteBonusBookVersion),
    fork(fetchUsers),
    fork(fetchLessonTests),
  ]);
}
