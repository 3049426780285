const actions = {
  FETCH_NOTI_CONFIGS: "FETCH_NOTI_CONFIGS",
  FETCH_NOTI_CONFIGS_SUCCESS: "FETCH_NOTI_CONFIGS_SUCCESS",
  SEARCH_USERS: "SEARCH_USERS",
  SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
  SET_FILTER: "SET_FILTER",
  SEND_PUSH: "SEND_PUSH",
  RESET: "RESET",
  SET_LOADING: "SET_LOADING",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  SET_COMPLETED: "SET_COMPLETED",

  fetchConfigs: () => ({
    type: actions.FETCH_NOTI_CONFIGS,
  }),
  searchUsers: (payload) => ({
    type: actions.SEARCH_USERS,
    payload,
  }),
  sendPush: (payload, cb) => ({
    type: actions.SEND_PUSH,
    payload,
    cb
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  fetchNotifications: () => ({
    type: actions.FETCH_NOTIFICATIONS,
  }),
};
export default actions;
