const actions = {
  FETCH_APPS: "FETCH_APPS",
  FETCH_APPS_SUCCESS: "FETCH_APPS_SUCCESS",
  SAVE_APPS: "SAVE_APPS",
  DELETE_APP: "DELETE_APP",
  SET_FILTER: "SET_FILTER",
  FETCH_APP_USERS: "FETCH_APP_USERS",
  FETCH_APP_USERS_SUCCESS: "FETCH_APP_USERS_SUCCESS",
  FETCH_APP_CONFIGS: "FETCH_APP_CONFIGS",
  FETCH_APP_CONFIGS_SUCCESS: "FETCH_APP_CONFIGS_SUCCESS",
  FETCH_APP_VERSION: "FETCH_APP_VERSION",
  FETCH_APP_VERSION_SUCCESS: " FETCH_APP_VERSION_SUCCESS",
  RESET: "RESET",
  fetchApps: (spinHandler) => ({
    type: actions.FETCH_APPS,
    spinHandler,
  }),
  saveApp: (payload, appId, cb) => ({
    type: actions.SAVE_APPS,
    payload,
    appId,
    cb,
  }),
  deleteApp: (appId, spinHandler) => ({
    type: actions.DELETE_APP,
    appId,
    spinHandler,
  }),
  fetchUsers: (payload, spinHandler) => ({
    type: actions.FETCH_APP_USERS,
    payload,
    spinHandler,
  }),
  fetchAppConfigs: () => ({
    type: actions.FETCH_APP_CONFIGS,
  }),
  fetchVersion: () => ({
    type: actions.FETCH_APP_VERSION,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
