import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  websites: null,
  bonusWebsites: null,
  roles: null,
  schools: null,
  grades: null,
  users: null,
  key: null,
  singleBonusWebSite: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_WEBSITES_SUCCESS:
      return state.set("websites", action.websites);
    case actions.FETCH_BONUS_WEBSITES_SUCCESS:
      return state.set("bonusWebsites", action.bonusWebsites);
    case actions.FETCH_BONUS_USERS_SUCCESS:
      return state.set("users", action.users);
    case actions.FETCH_BONUS_CONFIGS_SUCCESS:
      return state
        .set("roles", action.roles)
        .set("schools", action.schools)
        .set("grades", action.grades);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("websites", null);
    case actions.FETCH_SCHOOL_SUCCESS:
      return state.set("schools", action.schools);
    case actions.GET_USER_RECORD_WEB_SUCCESS:
      return state.set("singleBonusWebSite", action.bonusBook);
    default:
      return state;
  }
}
