const actions = {
  SAVE_INVESTOR: "SAVE_INVESTOR",
  DELETE_INVESTOR: "DELETE_INVESTOR",
  FETCH_PROPOSALS: "FETCH_PROPOSALS",
  FETCH_PROPOSALS_SUCCESS: "FETCH_PROPOSALS_SUCCESS",
  FETCH_ALL_INVESTORS: "FETCH_ALL_INVESTORS",
  FETCH_ALL_INVESTORS_SUCCESS: "FETCH_ALL_INVESTORS_SUCCESS",
  FETCH_ALL_PROPOSAL_NUMBERS: "FETCH_ALL_PROPOSAL_NUMBERS",
  FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS: "FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS",
  FETCH_ALL_PROPOSAL_NUMBERS_UPDATED: "FETCH_ALL_PROPOSAL_NUMBERS_UPDATED",
  FETCH_ALL_PROPOSAL_NUMBERS_UPDATED_SUCCESS:
    "FETCH_ALL_PROPOSAL_NUMBERS_UPDATED_SUCCESS",

  RESET: "RESET",

  saveInvestor: (payload, investorId, cb) => ({
    type: actions.SAVE_INVESTOR,
    payload,
    investorId,
    cb,
  }),
  fetchAllProposalNumbersUpdated: (spinHandler = null) => ({
    type: actions.FETCH_ALL_PROPOSAL_NUMBERS_UPDATED,
    spinHandler,
  }),

  deleteInvestor: (investorId, spinHandler) => ({
    type: actions.DELETE_INVESTOR,
    investorId,
    spinHandler,
  }),
  fetchProposals: (payload = {}, spinHandler = null) => ({
    type: actions.FETCH_PROPOSALS,
    payload,
    spinHandler,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  fetchAllInvestors: (spinHandler = null) => ({
    type: actions.FETCH_ALL_INVESTORS,
    spinHandler,
  }),
  fetchAllProposalNumbers: (spinHandler = null) => ({
    type: actions.FETCH_ALL_PROPOSAL_NUMBERS,
    spinHandler,
  }),
};
export default actions;
