import logoBlue from '../../image/Odin-Logo-blue.svg';
import logoWhite from '../../image/Odin-Logo-white.svg'

const menu = {
  content: {
    homeScreen: {
      key: "homeScreen",
      path: "/home",
      id: "homeScreen",
      label: "",
      iconActive: logoBlue,
      icon: logoWhite,
    },
    dashboard: {
      key: "dashboard",
      path: "",
      id: "dashboard",
      label: "Dashboard",
      iconActive: require("../../image/SidebarIcons/dashboard-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/dashboard-white.svg").default,
    },
    roles: {
      key: "role",
      path: "/role",
      id: "role",
      label: "Roles",
      iconActive: require("../../image/SidebarIcons/role-blue.svg").default,
      icon: require("../../image/SidebarIcons/role-white.svg").default,
    },
    admins: {
      key: "admin",
      path: "/admin",
      id: "admin",
      label: "Admins",
      iconActive: require("../../image/SidebarIcons/administrator-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/administrator-white.svg").default,
    },
    users: {
      key: "users",
      path: "/users",
      id: "users",
      label: "Users",
      iconActive: require("../../image/SidebarIcons/user-blue.svg").default,
      icon: require("../../image/SidebarIcons/user-white.svg").default,
    },
    schools: {
      key: "schools",
      path: "/schools",
      id: "schools",
      label: "Schools",
      iconActive: require("../../image/SidebarIcons/school-blue.svg").default,
      icon: require("../../image/SidebarIcons/school-white.svg").default,
    },
    subjects: {
      key: "subjects",
      path: "/subjects",
      id: "subjects",
      label: "Subjects",
      iconActive: require("../../image/SidebarIcons/subjects-blue.svg").default,
      icon: require("../../image/SidebarIcons/subjects-white.svg").default,
    },
    grades: {
      key: "grades",
      path: "/grades",
      id: "grades",
      label: "Grades",
      iconActive: require("../../image/SidebarIcons/grade-blue.svg").default,
      icon: require("../../image/SidebarIcons/grade-white.svg").default,
    },
    library: {
      key: "books_videos",
      path: "/library",
      id: "books_videos",
      label: "Library",
      iconActive: require("../../image/SidebarIcons/library-blue.svg").default,
      icon: require("../../image/SidebarIcons/library-white.svg").default,
    },
    mylibrary: {
      key: "bonusContent",
      path: "/mylibrary",
      id: "bonus_content",
      label: "My Library",
      iconActive: require("../../image/SidebarIcons/my-library-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/my-library-white.svg").default,
    },
    webplatforms: {
      key: "websites",
      path: "/webplatforms",
      id: "websites",
      label: "Web-Platforms",
      iconActive: require("../../image/SidebarIcons/web-platforms-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/web-platforms-white.svg").default,
    },
    mywebplatforms: {
      key: "bonusWebsites",
      path: "/mywebplatforms",
      id: "bonus_websites",
      label: "My Web-Platforms",
      iconActive: require("../../image/SidebarIcons/my-web-platforms-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/my-web-platforms-white.svg")
        .default,
    },
    devices: {
      key: "devices",
      path: "/devices",
      id: "devices",
      label: "Devices",
      iconActive: require("../../image/SidebarIcons/devices-blue.svg").default,
      icon: require("../../image/SidebarIcons/devices-white.svg").default,
    },
    psm: {
      key: "psm",
      path: "/psm",
      id: "psm",
      label: "PSM",
      iconActive: require("../../image/SidebarIcons/psm-blue.svg").default,
      icon: require("../../image/SidebarIcons/psm-white.svg").default,
    },
    notification: {
      key: "notification",
      path: "/notification",
      id: "notification",
      label: "Notification",
      iconActive: require("../../image/SidebarIcons/notification-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/notification-white.svg").default,
    },
    import: {
      key: "import",
      path: "/import",
      id: "import",
      label: "Import",
      iconActive: require("../../image/SidebarIcons/import-blue.svg").default,
      icon: require("../../image/SidebarIcons/import-white.svg").default,
    },
    chromeversion: {
      key: "chromeVersion",
      path: "/chromeVersion",
      id: "chromeVersion",
      label: "Chrome Version",
      iconActive: require("../../image/SidebarIcons/app-version-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/app-version-white.svg").default,
    },
    appversion: {
      key: "appVersion",
      path: "/appVersion",
      id: "appVersion",
      label: "App Version",
      iconActive: require("../../image/SidebarIcons/app-version-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/app-version-white.svg").default,
    },
    custom: {
      key: "custom",
      path: "/custom",
      id: "custom",
      label: "Custom",
      iconActive: require("../../image/SidebarIcons/custom-blue.svg").default,
      icon: require("../../image/SidebarIcons/custom-white.svg").default,
    },
    mcqtest: {
      key: "test",
      path: "/test",
      id: "test",
      label: "MCQ Test",
      iconActive: require("../../image/SidebarIcons/test-blue.svg").default,
      icon: require("../../image/SidebarIcons/test-white.svg").default,
    },
    survey: {
      key: "survey",
      path: "/survey",
      id: "survey",
      label: "Survey",
      iconActive: require("../../image/SidebarIcons/survey-blue.svg").default,
      icon: require("../../image/SidebarIcons/survey-white.svg").default,
    },
    rewards: {
      key: "rewards",
      path: "/rewards",
      id: "rewards",
      label: "Rewards",
      iconActive: require("../../image/SidebarIcons/rewards-blue.svg").default,
      icon: require("../../image/SidebarIcons/rewards-white.svg").default,
    },
    chatgroups: {
      key: "chatgroups",
      path: "/chat_groups",
      id: "chatgroups",
      label: "Chat Groups",
      iconActive: require("../../image/SidebarIcons/chat-blue.svg").default,
      icon: require("../../image/SidebarIcons/chat-white.svg").default,
    },

    technicalsupport: {
      key: "health",
      path: "/technicalsupport",
      id: "health",
      label: "Technical Support",
      iconActive: require("../../image/SidebarIcons/tech-support-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/tech-support-white.svg").default,
    },
    // zoommeeting: {
    //   key: "zoommeeting",
    //   path: "/zoom",
    //   id: "zoommeeting",
    //   label: "Zoom Meeting",
    //   icon: "ion-chatbubble",
    // },
    bigbluebutton: {
      key: "bigbluemeeting",
      path: "/bigbluemeeting",
      id: "bigbluemeeting",
      label: "Big Blue Button",
      iconActive: require("../../image/SidebarIcons/BBB-blue.svg").default,
      icon: require("../../image/SidebarIcons/BBB-white.svg").default,
    },
    investor: {
      key: "investor",
      path: "/investor",
      id: "investor",
      label: "Investor",
      iconActive: require("../../image/SidebarIcons/sponsor.jpg").default,
      icon: require("../../image/SidebarIcons/sponsor.jpg").default,
    },
    investorproposal: {
      key: "proposal",
      path: "/investor_proposal",
      id: "proposal",
      label: "Investor Proposal",
      iconActive: require("../../image/SidebarIcons/proposal-blue.svg").default,
      icon: require("../../image/SidebarIcons/proposal-white.svg").default,
    },
    feedback: {
      key: "feedback",
      path: "/feedback",
      id: "feedback",
      label: "Feedback",
      iconActive: require("../../image/SidebarIcons/testimonial-blue.svg")
        .default,
      icon: require("../../image/SidebarIcons/testimonial-white.svg").default,
    },
  },
};

export default menu;
