import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

const formatResponse = (resp) => {
  let formattedArray = [];
  resp.forEach((item) => {
    formattedArray.push({
      _id: item._id,
      title: item.payloadInfo.title,
      description: item.payloadInfo.body,
      pushNotificationResponse: item.pushNotifResponse,
    });
  });
  return formattedArray;
};

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_NOTI_CONFIGS, function*(action) {
    try {
      const roles = yield call(API.common.roles);
      const schools = yield call(API.common.schools);
      const grades = yield call(API.common.grades);
      yield put({
        type: actions.FETCH_NOTI_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
        roles: roles && roles.data.roles ? roles.data.roles : [],
        grades: grades && grades.data.grades ? grades.data.grades : [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.SEARCH_USERS, function*(action) {
    try {
      const users = yield call(API.users.searchV2, action.payload);
      yield put({
        type: actions.SEARCH_USERS_SUCCESS,
        users: users && users.data.data ? users.data.data : [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* sendPush() {
  yield takeEvery(actions.SEND_PUSH, function*(action) {
    try {
      yield call(API.users.notification, action.payload);
      Notification("success", "Notification sent successfully");
      yield call(action.cb);
      yield put({
        type: actions.SET_COMPLETED,
      });

      // yield put({
      //   type: actions.FETCH_NOTIFICATIONS,
      // });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchNotifications() {
  yield takeEvery(actions.FETCH_NOTIFICATIONS, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const response = yield call(API.users.listNotification);

      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      if (response && response.length) {
        yield put({
          type: actions.FETCH_NOTIFICATIONS_SUCCESS,
          notifications: formatResponse(response),
        });
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchConfigs),
    fork(fetchUsers),
    fork(sendPush),
    fork(fetchNotifications),
  ]);
}
