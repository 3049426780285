/**
 * Auth selectors
 */

import { createSelector } from "reselect";

const selectAuth = (state) => state.Auth;

const getProfile = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.get("profile");
  });

const getBlockedMenuList = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["role"]["blockedMenus"];
  });

const getToken = () =>
  createSelector(selectAuth, (tokenState) => tokenState.get("token"));

const getRole = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["role"]["handle"];
  });

const getQualityEngagementPermission = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["qualityEngagementFactorPermission"];
  });

const getSchool = () =>
  createSelector(selectAuth, (profileState) => {
    if (profileState.getIn(["profile"])["school"])
      return {
        name: profileState.getIn(["profile"])["school"]["name"],
        id: profileState.getIn(["profile"])["school"]["_id"],
      };
    return {};
  });

const getUserSchool = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["school"];
  });

export {
  getProfile,
  getToken,
  getBlockedMenuList,
  getRole,
  getSchool,
  getUserSchool,
  getQualityEngagementPermission,
};
