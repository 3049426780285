import App from "./app/reducer";
import Appversion from "./appVersions/reducer";
import Otherappversion from "./chromeVersions/reducer";
import Auth from "./auth/reducer";
import Books from "./books/reducer";
import Devices from "./devices/reducer";
import Group from "./chatgroups/reducer";
import Grades from "./grades/reducer";
import Import from "./imports/reducer";
import Notification from "./notifications/reducer";
import Psm from "./psm/reducer";
import Rewards from "./rewards/reducer";
import Schools from "./schools/reducer";
import Subjects from "./subjects/reducer";
import Survey from "./surveys/reducer";
import Test from "./test/reducer";
import Users from "./users/reducer";
import Websites from "./websites/reducer";
import DeviceTransaction from "./deviceTransaction/reducer";
import Role from "./role/reducer";
import Admin from "./admin/reducer";
import Health from "./health/reducer";
import dashboard from "./dashboard/reducer";
import Zoom from "./zoom/reducer";
import bigBlue from "./bigBlue/reducer";
import Investors from "./investors/reducer";
import InvestorProposal from "./investorProposal/reducer";
import area from "./area/reducer";
import type from "./Type/reducer";
export default {
  App,
  Appversion,
  Otherappversion,
  Auth,
  Books,
  Devices,
  Grades,
  Group,
  Import,
  Investors,
  InvestorProposal,
  Notification,
  DeviceTransaction,
  Psm,
  Rewards,
  Schools,
  Subjects,
  Survey,
  Test,
  Users,
  Websites,
  Role,
  Admin,
  dashboard,
  Health,
  Zoom,
  bigBlue,
  area,
  type,
};
