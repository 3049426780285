import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, getProfile, clearToken } from "../../helpers/utility";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchUsers() {
  yield takeEvery("FETCH_USERS", function*(action) {
    try {
      // const resp = yield call(API.users.list);
      const users = yield call(API.users.search, action.payload);
      yield put({
        type: actions.FETCH_USERS_SUCCESS,
        users: users && users.data.users ? users.data.users : [],
      });
      if (action?.spinHandler) yield call(action.spinHandler, false);
    } catch (err) {
      if (action?.spinHandler) yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleUser() {
  yield takeEvery("FETCH_USER_DETAILS", function*(action) {
    try {
      const resp = yield call(API.users.details, action.userId);
      yield put({
        type: actions.FETCH_USER_DETAILS_SUCCESS,
        user: resp.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveUser() {
  yield takeEvery(actions.SAVE_USER, function*(action) {
    console.log(action.payload);
    try {
      let resp, message;
      if (action.userId) {
        resp = yield call(API.users.update, action.payload, action.userId);
        message = "User updated successfully";
      } else {
        resp = yield call(API.users.add, action.payload);
        message = "User created successfully";
      }
      Notification("success", message);
      yield call(action.loader, true);
    } catch (err) {
      yield call(action.loader, false);
      Notification(
        "error",
        err?.data?.error?.message ||
          err?.data?.message ||
          "Session timed out. Please login again"
      );
    }
  });
}

export function* updateAvatar() {
  yield takeEvery(actions.UPLOAD_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        const data = new FormData();
        if (action.payload.file.originFileObj) {
          data.append("avatar", action.payload.file.originFileObj);
          data.append("userid", action.userId);
          resp = yield call(API.users.uploadAvatar, data, action.userId);
          message = "Avatar updated successfully";
        }
      }
      Notification("success", message);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteUser() {
  yield takeEvery(actions.DELETE_USER, function*(action) {
    try {
      yield call(API.users.delete, action.userId);
      // const resp = yield call(API.users.list);
      // yield put({
      //   type: actions.FETCH_USERS_SUCCESS,
      //   users: resp.data.users,
      // });
      Notification("success", "User deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateUserStatus() {
  yield takeEvery(actions.CHANGE_STATUS, function*(action) {
    try {
      yield call(API.users.update, action.payload, action.userId);
      yield put({
        type: actions.CHANGE_STATUS_SUCCESS,
        userId: action.userId,
        isActive: action.payload.isActive,
      });
      yield call(action.cb, false);
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* bulkUpdateUsers() {
  yield takeEvery(actions.BULK_UPDATE_USERS, function*(action) {
    try {
      const response = yield call(API.users.bulk_update, action.payload);
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      if (response && response.success) {
        yield put({
          type: actions.BULK_UPDATE_USERS_SUCCESS,
          status: "success",
        });

        yield put({
          type: actions.FETCH_USERS,
        });

        Notification("success", response.message);
        if (action.cb) yield call(action.cb, true);
      }
    } catch (err) {
      if (action.cb) yield call(action.cb, false);
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS, function*(action) {
    try {
      const roles = yield call(API.common.roles);
      const schools = yield call(API.common.schools);
      const grades = yield call(API.common.grades);
      const subjects = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_CONFIGS_SUCCESS,
        roles: roles && roles.data.roles ? roles.data.roles : [],
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: grades && grades.data.grades ? grades.data.grades : [],
        subjects:
          subjects && subjects.data.subjects ? subjects.data.subjects : [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* multiDelete() {
  yield takeEvery(actions.DELETE_MULTIPLE, function*(action) {
    try {
      yield call(API.users.multiDelete, action.payload.userIds);
      // const resp = yield call(API.users.list);
      // yield put({
      //   type: actions.FETCH_USERS_SUCCESS,
      //   users: resp.data.users,
      // });
      Notification("success", "Users Removed successfully");
      yield call(action.payload.callBack, false);
    } catch (err) {
      yield call(action.payload.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchUsers),
    fork(saveUser),
    fork(updateAvatar),
    fork(deleteUser),
    fork(fetchSingleUser),
    fork(fetchConfigs),
    fork(updateUserStatus),
    fork(multiDelete),
    fork(bulkUpdateUsers),
  ]);
}
