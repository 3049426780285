const actions = {
  FETCH_SCHOOLS: "FETCH_SCHOOLS",
  FETCH_SCHOOLS_SUCCESS: "FETCH_SCHOOLS_SUCCESS",
  SAVE_SCHOOL: "SAVE_SCHOOL",
  DELETE_SCHOOL: "DELETE_SCHOOL",
  FETCH_SCHOOL_DETAILS: "FETCH_SCHOOL_DETAILS",
  FETCH_SCHOOL_DETAILS_SUCCESS: "FETCH_SCHOOL_DETAILS_SUCCESS",
  SET_FILTER: "SET_FILTER",
  CHANGE_STATUS_SCHOOL: "CHANGE_STATUS_SCHOOL",
  CHANGE_STATUS_SCHOOL_SUCCESS: "CHANGE_STATUS_SCHOOL_SUCCESS",

  RESET: "RESET",
  fetchSchools: (spinHandler) => ({
    type: actions.FETCH_SCHOOLS,
    spinHandler,
  }),
  saveSchool: (payload, schoolId, cb) => ({
    type: actions.SAVE_SCHOOL,
    payload,
    schoolId,
    cb,
  }),
  deleteSchool: (payload, schoolId, spinHandler) => ({
    type: actions.DELETE_SCHOOL,
    payload,
    schoolId,
    spinHandler,
  }),
  fetchSingleSchool: (schoolId, spinHandler) => ({
    type: actions.FETCH_SCHOOL_DETAILS,
    schoolId,
    spinHandler,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  changeStatus: (payload, schoolId, cb) => ({
    type: actions.CHANGE_STATUS_SCHOOL,
    payload,
    schoolId,
    cb,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
