import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Select } from "antd";
import appActions from "../../redux/app/actions";

import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import TopbarUser from "./topbarUser";
import Logo from "../../image/logo2.png";
import TopbarUserDetails from "./TopbarUserDetails";

const { Header } = Layout;
const { toggleCollapsed, getSchools } = appActions;
const customizedTheme = themes[themeConfig.theme];
const Option = Select.Option;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "english",
      school: undefined,
    };
  }

  componentWillMount() {
    //this.props.getSchools();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.appLang) {
      this.setState({ lang: nextProps.appLang.languageId });
    }
    if (this.props.schools !== nextProps.schools) {
      let school = undefined;
      if (localStorage.getItem("School")) {
        school = localStorage.getItem("School").replace(/['"]+/g, "");
      }
      this.setState({ school });
    }
  }

  handleChangeLang = (lang) => {
    this.setState({ lang });
    const _this = this;
    this.props.changeLanguage(lang, this.props.user._id);
  };

  // handleChange = (val) => {
  //   this.setState({ school: val });
  //   if (val) {
  //     localStorage.setItem("School", JSON.stringify(val));
  //   } else {
  //     localStorage.removeItem("School");
  //   }
  //   window.location.reload();
  // };

  render() {
    const { toggleCollapsed, notifications, schools, role } = this.props;
    const { lang, school } = this.state;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <img src={Logo} />
            {/* <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            /> */}
          </div>

          <ul className="isoRight">
            {/* {role === "SUPER_ADMIN" && (
              <div style={{ marginRight: 5 }}>
                <Select
                  className="remove"
                  allowClear
                  showSearch
                  showArrow={false}
                  onChange={(value) => this.handleChange(value)}
                  // onSearch={this.searchUser}
                  value={school}
                  style={{ width: 250 }}
                  placeholder={"Select School"}
                >
                  {schools &&
                    schools.map((el, key) => (
                      <Option key={key} value={el._id}>
                        {el.name}
                      </Option>
                    ))}
                </Select>
              </div>
            )} */}
            <li className="isoUser">
              <TopbarUserDetails />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            />
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    collapsed: state.App.collapsed,
    openDrawer: state.App.openDrawer,
    user: state.Auth.get("profile"),
    role: state.Auth.getIn(["profile"])["role"]["handle"],
    schools: state.App.schools,
  }),
  { toggleCollapsed, getSchools }
)(Topbar);
