import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  investors: [],
  proposals: [],
  allInvestors: [],
  allProposalNumbers: [],
  allProposals: [],
});

export default function investorsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_PROPOSALS_SUCCESS:
      return state.set("proposals", action.list);
    case actions.RESET:
      return state.set("investors", []);
    case actions.FETCH_ALL_INVESTORS_SUCCESS:
      return state.set("allInvestors", action.allInvestors);
    case actions.FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS:
      return state.set("allProposalNumbers", action.allProposalNumbers);
    case actions.FETCH_ALL_PROPOSAL_NUMBERS_UPDATED_SUCCESS:
      return state.set("allProposals", action.allProposals);

    default:
      return state;
  }
}
